<template>

  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <div class="row campaign mt-1">
      <b-col lg="12">
        <b-card>
          <b-card-body>
            <b-alert show>
              Laporan user yang mengikuti kampanye. Laporan ditampilkan sesuai dengan name kampanye.
            </b-alert>
            <div class="form-group row">

              <label for="" class="col-sm-2"> Search Campaign <br /> </label>
              <div class="col-sm-6">
                <multiselect 
                  label="name"
                  track-by="name"
                  placeholder="Type to Search"
                  :close-on-select="true"
                  :multiple="false"
                  :searchable="true"
                  :options-limit="10"
                  :max="10"
                  :options="items_search"
                  :loading="isLoadingSearch"
                  v-model="filter"
                  @search-change="asyncFind"
                  @input="actionFindById"
                >
                </multiselect>
              </div>
              <div class="col">
                <b-button variant="link" @click="reset">Reset</b-button>
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row" v-if="items.length">
              <div class="col-md-6">
                Count <strong>{{ totalRows }}</strong>
              </div>
              <div class="col-md-6 text-right" v-if="items.length ? true : false">
                <b-button variant="primary" @click="downloadReport">
                  <span class="fa fa-download mx-1" v-if="!isDownload"></span>
                  <b-spinner style="width: 20px; height: 20px;" class="align-middle" v-else></b-spinner>
                </b-button>
              </div>
            </div>
            <table class="table table-striped table-bordered table-sm table-hover table-responsive-sm" v-if="items.length">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Discount name</th>
                  <th>Discount Type</th>
                  <th>Order</th>
                  <th>Discount</th>
                  <th>Users</th>
                  <th>Date Order</th>
                </tr>
              </thead>
              <tbody v-for="(item, index) in items" :key="item.id">
                <tr>
                  <td>{{ (currentPage - 1) * perPage + (index + 1) }}</td>
                  <td>{{ item.discount_name }}</td>
                  <td>{{ promotionType(item.discount_type) }}</td>
                  <td>{{ item.order ? item.order.order_number : "" }}</td>
                  <td>{{ item.discount_value }}</td>
                  <td>{{ reverseEmail(item.order) }}</td>
                  <td style="width:10%">
                    {{ formatDate(item.order ? item.order.created : "") }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row col" v-if="!items.length && filter && !isLoading"><h5>not found</h5></div>
          </b-card-body>
          <b-row class="justify-content-start ml-1" v-if="items.length">
            <b-col lg="3">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                aria-controls="my-table" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </div>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "campaign",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: null,
      isLoadingSearch: false,
      isDownload: false,
      items_search: [],
    };
  },
  computed: {
    ...mapGetters("campaign", { items: "reportcampaign" }),
    ...mapState({
      isLoading: (state) => state.campaign.isLoading,
      isError: (state) => state.campaign.isError,
      totalRows: (state) => state.campaign.totalCampaignRows,
    }),
  },
  watch: {
    currentPage: function () {
      this.actionFindById();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Reports Campaign | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  mounted() {
    this.actionGetCampaign();
  },
  methods: {
    ...mapActions("campaign", [
      "fetchCampaign",
      "searchCampaign",
      "fetchCampaignByid",
      "actionGetReportCampaign",
      'actionSendReportCampaign',
    ]),
    reverseEmail(order) {
      return order.users ? order.users.email : "";
    },
    actionGetCampaign() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };

      this.fetchCampaign(payload);
    },
    downloadReport() {
      this.$swal({
        title: 'Email',
        input: 'email',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Send',
        showLoaderOnConfirm: true,
        preConfirm: (data) => {
          this.isDownload = true;
          this.$store.commit(`campaign/setLoading`, true);
          this.actionSendReportCampaign({
            id: this.filter.id,
            email_to: data,
          }).then((response) => {
            if (response.status != 200) {
              let message = response.statusText;
              message = response.data?.message ?? message;
              this.messageAlert('error', message);
            } else {
              this.messageAlert('success', response.data.message);
            }
            this.isDownload = false;
            this.$store.commit(`campaign/setLoading`, false);
          }).catch(() => {
            this.messageAlert('error', 'Oops! Something went wrong. Please contact your administrator');
            this.isDownload = false;
            this.$store.commit(`campaign/setLoading`, false);
          });
        },
        allowOutsideClick: false,
      });

    },
    formatDate(tgl) {
      return moment(tgl).format("DD MMM YYYY");
    },
    promotionType(key) {
      if (key == 1) {
        return "Discount Offers";
      } else if (key == 2) {
        return "Discount Orders";
      } else if (key == 3) {
        return "Discount payment gateway offer";
      } else if (key == 4) {
        return "Discount paytment gateway order";
      } else if (key == 5) {
        return "Discount Code";
      }
    },
    asyncFind(query) {
      if (!query) return;
      this.isLoadingSearch = true;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchCampaign({q: query})
            .then((response) => {
              this.isLoadingSearch = false;
              this.items_search = response.data.data.rows;
            })
            .catch((error) => {
              console.log(error);
              this.isLoadingSearch = false;
            });
      }, 600);
    },
    actionFindById() {
      if (!this.filter?.id) {
        return;
      }
      setTimeout(() => {
        let payload = {
          id: this.filter.id,
          page: this.currentPage,
          limit: this.perPage,
        };
        // console.log(payload);
        // console.log("ini filte " + JSON.stringify(this.filter));
        this.actionGetReportCampaign(payload);
      }, 500);
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    reset() {
      this.filter = null;
      this.$store.commit(`campaign/setReportCampaign`, []);
    },
  },
};
</script>

<style scoped>
.campaign {
  font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
}
</style>
